import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";

import PageHeader from "../components/product-page-header";
import PostMain from "../components/post-main";
import ProductMain from "../components/product-main";
import FeaturePageHeader from "../components/feature-page-header";

export const query = graphql`
  fragment Si on SanityImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query FeatureTemplateQuery($id: String!) {
    featurePage: sanityFeaturePage(id: { eq: $id }) {
      id
      title
      flexibleContent {
        ... on SanityFeaturePageHeader {
          _type
          title
          button {
            blank
            secondaryButton
            text
            url
          }
          subtitle
          bg {
            ...Si
          }
        }
        ... on SanityFcImage {
          _key
          _type
          fullwidth
          image {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityProductFeaturesNew {
          _type
          title
          caption
          verticalTabs
          features {
            featureImage {
              ...Si
            }
            title
            featureItems {
              title
              description
              caption
              icon {
                ...Si
              }
              cta {
                title
                type
                slug {
                  __typename
                  ... on SanityFeaturePage {
                    slug {
                      current
                    }
                    correspodingProduct {
                      slug {
                        current
                      }
                    }
                  }
                  ... on SanityProductPage {
                    slug {
                      current
                    }
                  }
                }
                link
              }
            }
          }
        }
        ... on SanityDeploymentOptions {
          _type
          title
          options {
            header
            _rawHybrid
            _rawOnDemand
            _rawDedicated
          }
        }
        ... on SanityCaptionTitleText {
          _type
          title
          _rawDescription
          caption
        }
        ... on SanityProductCallToAction {
          _type
          title
          _rawDescription
          button {
            blank
            secondaryButton
            text
            url
          }
          bg {
            ...Si
          }
        }
        ... on SanityFeatureOverview {
          _type
          title
          caption
          features {
            title
            description
            icon {
              ...Si
            }
            cta {
              title
              type
              slug {
                __typename
                ... on SanityFeaturePage {
                  slug {
                    current
                  }
                  correspodingProduct {
                    slug {
                      current
                    }
                  }
                }
                ... on SanityProductPage {
                  slug {
                    current
                  }
                }
              }
              link
            }
          }
        }
        ... on SanityFeaturedBlock {
          _key
          _type
          caption
          featureStyling
          title
          _rawContent
          link {
            blank
            text
            url
          }
          image {
            ...SanityImage
            alt
          }
          switchOrder
        }
        ... on SanityProductHowItWorks {
          _type
          title
          subtitle
          _rawContent
          image {
            ...SanityImage
            alt
          }
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`;

const FeatureTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const page = (data || {})?.featurePage;

  const title = page.seo?.title || page.title;
  const description = page.seo?.description;
  const image = page.seo?.thumbnail || page.thumbnail;
  const autoSiteTitle = !page.seo?.title;
  const pageHeaderBlock = page.flexibleContent.find(
    (block) => block._type === "featurePageHeader"
  );

  const updatedFlexibleContent = page.flexibleContent.filter(
    (block) => block._type !== "featurePageHeader"
  );

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />
      {pageHeaderBlock.title && (
        <FeaturePageHeader
          title={pageHeaderBlock.title}
          subtitle={pageHeaderBlock.subtitle}
          bg={pageHeaderBlock.bg}
          button={pageHeaderBlock.button}
        />
      )}
      {page.flexibleContent && <ProductMain content={updatedFlexibleContent} />}
    </Layout>
  );
};

export default FeatureTemplate;
