import React, { useState, useEffect } from "react";
import Container from "./container";
import { cn, seoUrl, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { Link } from "gatsby";

import * as styles from "../styles/components/feature-page-header.module.css";
import Icon from "./icon";

const FeaturePageHeader = (props) => {
  const { title, bg, subtitle, button } = props;

  // Get viewport size
  const hasWindow = typeof window !== "undefined";
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );
    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }
    }, []);
    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  return (
    <>
      <div className={styles.root}>
        <Container>
          <div className={styles.flexCol}>
            {subtitle && (
              <div className={cn("h3", styles.subtitle)}>{subtitle}</div>
            )}
            {title && (
              <div className={styles.contentWrap}>
                <h1 className={styles.title}>{title}</h1>
              </div>
            )}
            {bg && bg.asset && (
              <div className={styles.bg}>
                <img
                  src={imageUrlFor(buildImageObj(bg)).auto("format").url()}
                  alt=""
                />
              </div>
            )}
            {button && button.url && (
              <>
                {button.blank ? (
                  <a
                    href={button.url}
                    className={cn(
                      "btn",
                      styles.button,
                      button.secondaryButton
                        ? "secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke"
                        : "color-palette-pseudo-bg"
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {button.text}
                    <Icon symbol="arrow-right" />
                  </a>
                ) : (
                  <Link
                    to={button.url}
                    className={cn(
                      "btn",
                      styles.button,
                      button.secondaryButton
                        ? "secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke"
                        : "color-palette-pseudo-bg"
                    )}
                  >
                    {button.text}
                    <Icon symbol="arrow-right" />
                  </Link>
                )}
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default FeaturePageHeader;
